// Aqui haremos las llamadas a todos los endpoints que cuelguen de eventtype
import Pui9HttpRequests from '@Pui9Requests';

export async function disableEngineTypes(enginetype) {
	const controller = '/aqenginetypes/disableEngineTypes';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		enginetype,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableEngineTypes(enginetype) {
	const controller = '/aqenginetypes/enableEngineTypes';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		enginetype,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}
