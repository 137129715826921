<template>
	<div>
		<pui-datatable :modelName="model" :showDeleteBtn="true" :actions="actions" :modelColumnDefs="columnDefs"></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
import EngineTypesActions from './AqEngineTypesActions';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'aqenginetypesgrid',
	data() {
		return {
			model: 'aqenginetypes',
			actions: EngineTypesActions.actions,
			columnDefs: {
				disabled: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				}
			}
		};
	}
};
</script>

<style lang="postcss" scoped></style>
